import React from "react"

import Layout from "../components/layout"

import SEO from "../components/seo"
import Image from "../components/image"

const IndexPage = () => (
  <Layout>
    <SEO title="Martin Castellanos" keywords={[`Martin`, `Castellanos`, `software`, `engineer`, `developer`]} />
    <div className="tilted-section">
      <h1 className="title">Martin <br/> Castellanos</h1>
      <div className="subtitle">
        product engineer @ airtable
      </div>
    </div>


    <div className="portrait">
      <Image />
    </div>
  </Layout>
)

export default IndexPage
